<!-- 用户编辑弹窗 -->
<template>
    <el-dialog
            :title="isUpdate?'修改评定标准配置':'添加评定标准配置'"
            :visible.sync="visible"
            width="50%"
            :destroy-on-close="true"
            :lock-scroll="false"
            custom-class="ele-dialog-form"
            :append-to-body="true"
            @update:visible="updateVisible">
        <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <!--      等级-->
          <el-form-item  label-width="10.5em" label="等级" prop="level_id" >
            <el-col :span="10">
              <el-select :span="24"  v-model="ruleForm.level_id" multiple placeholder="请选择等级">
                <el-option :span="24"
                           v-for="item in levelList"
                           :label="item.name" :value="item.id+''"></el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label-width="10.5em" label="升级百分比" prop="upgrade">
            <el-col :span="10">
              <el-input v-model.number="ruleForm.upgrade" placeholder="请输入升级百分比"></el-input>
            </el-col>
          </el-form-item>
            <el-form-item label-width="10.5em" label="同级百分比最小值" prop="same_min">
                <el-col :span="10">
                    <el-input v-model.number="ruleForm.same_min" placeholder="请输入百分比最小值"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label-width="10.5em" label="同级百分比最大值" prop="same_max">
                <el-col :span="10">
                    <el-input v-model.number="ruleForm.same_max" placeholder="请输入百分比最大值"></el-input>
                </el-col>
            </el-form-item>
          <el-form-item  label-width="10.5em"label="降级百分比" prop="demotion">
            <el-col :span="10">
              <el-input v-model.number="ruleForm.demotion" placeholder="请输入降级百分比"></el-input>
            </el-col>
          </el-form-item>

            <!--提交      -->


        </el-form>
        <div slot="footer">
            <el-button
                    @click="resetForm">取消
            </el-button>
            <el-button
                    type="primary"
                    @click="save"
                    :loading="loading">保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'PreEdit',
        props: {
            // 弹窗是否打开
            visible: Boolean,
            // 修改回显的数据
            data: Object
        },
        data() {
            return {
                // 表单数据
                init: {},
                // 表单验证规则domains
              ruleForm: Object.assign({}, this.data, {
                  module_code: this.data ? (this.data.module_code === 0 ? null : this.data.module_code) : null,
                level_id:[]}),
                // 等级下拉数据
                gradeSelection: [],
                rules: {
                  level_id: [
                    {required: true, message: '请选择等级',trigger: 'blur'}
                  ],
                  upgrade: [
                    {required: true, message: '请输入升级级百分比',trigger: 'blur'}
                  ],
                  same_max: [
                        {required: true, message: '请输入同级百分比最大值',trigger: 'blur'}
                    ],
                  same_min: [
                        {required: true, message: '请输入同级百分比最小值',trigger: 'blur'}
                    ],
                  demotion: [
                        {required: true, message: '请输入降级百分比',trigger: 'blur'}
                    ],
                },
                // 提交状态
                loading: false,
                // 是否是修改
                isUpdate: false,
                levelList:[]
            }
        },
        mounted() {
          this.queryLevels();  // 查询等级列表
        },
        watch: {
            data() {
                if (this.data) {
                  this.$http['get']('/Admin/preInfo/' + this.data.id).then(res => {
                    if(res.data.data){
                      this.ruleForm = Object.assign({module_code:this.data.module_code}, res.data.data);
                      this.isUpdate = true;
                    }else {
                      this.ruleForm = Object.assign({module_code:this.data.module_code,level_id:[]});
                      this.isUpdate = false;
                    }
                  })
                } else {
                    this.ruleForm = {module_code:this.data.module_code,level_id:[]};
                    this.isUpdate = false;
                }
            }
        },
        methods: {
            /* 保存编辑 */
            save() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$http[ 'post']('/Admin/preevaluate', this.ruleForm).then(res => {
                            this.loading = false;
                            if (res.data.code === 200) {
                                this.$message({type: 'success', message: res.data.msg});
                                if (!this.isUpdate) {
                                    this.ruleForm = {};
                                }
                                this.updateVisible(false);
                                this.$emit('done');
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    } else {
                        return false;
                    }
                });
            },
          // 关闭
          resetForm(ruleForm){
            // 调用父组件方法 隐藏弹框
            // this.openPreEdit(null)

            this.ruleForm = {};
            this.$emit('cancel',false)
          },
          /* 更新visible */
          updateVisible(value) {
              this.$emit('update:visible', value);
          },
          /* 查询等级列表 */
          queryLevels() {
            this.$http.get('/Admin/level').then(res => {

              if (res.data.code === 200) {
                this.levelList = res.data.data;
                //console.log(this.roleList)
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              this.$message.error(e.message);
            });
          },

        }
    }
</script>

<style scoped>
</style>
