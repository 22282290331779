<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="'前测评定标准列表'"
    :visible.sync="visible"
    width="70%"
    :destroy-on-close="true"
    :lock-scroll="false"
    :append-to-body="true"
    @close="resetForm"
    custom-class="ele-dialog-form">
    <!-- 数据表格 -->
    <ele-pro-table
      ref="table"
      :response="response"
      :datasource="url+ this.data.module_code"
      :columns="columns"
      :parse-data="parseData"
      row-key="id"
      height="calc(100vh - 265px)"
      default-expand-all
      :need-page="false">
      <!-- 表头工具栏 -->
      <template slot="toolbar">
        <el-button
          @click="openPreEdit(null,data.module_code)"
          type="primary"
          icon="el-icon-plus"
          class="ele-btn-icon"
          size="small">添加
        </el-button>
      </template>
      <!-- 标题列 -->
      <template slot="title" slot-scope="{row}">
        <i :class="row.icon"></i> {{ row.title }}
      </template>
      <!-- 类型列 -->
      <template slot="menu_type" slot-scope="{row}">
        <el-tag
          :type="['primary','info'][row.menu_type]"
          size="mini">
          {{ ['菜单', '按钮'][row.menu_type] }}
        </el-tag>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="{row}">
        <el-link
          @click="openPreEdit(row,row.module_code)"
          icon="el-icon-edit"
          type="primary"
          :underline="false">修改
        </el-link>
        <el-popconfirm
          title="确定要删除吗？"
          @confirm="remove(row)"
          class="ele-action">
          <el-link
            slot="reference"
            icon="el-icon-delete"
            type="danger"
            :underline="false">删除
          </el-link>
        </el-popconfirm>
      </template>
    </ele-pro-table>
    <!-- 前测弹窗 -->
    <pre-edit
      @cancel="openPreEdit"
      :visible.sync="showPreEdit"
      :data="current"
      @done="reload"/>
  </el-dialog>
</template>

<script>
import PreEdit from './pre-edit'
export default {
  name: 'PreIndex',
  components: {PreEdit},
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表格数据接口
      url: '/Admin/preIndex/',
      response: {
        statusCode: 200,
      },
      // 表格列配置
      columns: [
        {
          columnKey: 'index',
          type: 'index',
          width: 45,
          align: 'center',
          fixed: 'left',
          showOverflowTooltip: true
        },
        {
          prop: 'level_name',
          label: '等级',
          showOverflowTooltip: true,
          minWidth: 110,
         // slot: 'level_name'
        },
        {
          prop: 'upgrade',
          label: '升级百分比',
          showOverflowTooltip: true,
          minWidth: 110,
          //slot: 'subtitle'
        },
        {
          prop: 'same_min',
          label: '同比百分比最小值',
          showOverflowTooltip: true,
          minWidth: 110,
          //slot: 'image'
        },
        {
          prop: 'same_max',
          label: '同比百分比最大值',
          align: 'center',
          showOverflowTooltip: true,
          width: 140
        },
        {
          prop: 'demotion',
          label: '降级百分比',
          showOverflowTooltip: true,
          minWidth: 100
        },
        {
          columnKey: 'action',
          label: '操作',
          width: 190,
          align: 'center',
          resizable: false,
          slot: 'action'
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showPreIndex: true,
      showPreEdit: false,
      // 全部菜单数据
      menuList: [],
    }
  },
  mounted() {
    // this.$store.state.user.getMenus();
  },
  methods: {
    // 关闭
    resetForm(ruleForm){
      // 调用父组件方法 隐藏弹框
      this.ruleForm = {};
      this.$emit('cancel',false)
    },
    /* 解析接口返回数据 */
    parseData(res) {
      res.data = this.$util.toTreeData(res.data, 'id', 'parent_id');
      this.menuList = res.data;
      return res;
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload();
    },
    /* 重置搜索 */
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /* 显示编辑 */
    /*openPreEdit(row, parentId) {
      this.current = Object.assign({
        module_code: this.data.module_code,
        parent_id: parentId
      }, row);
      this.showPreEdit = true;
    },*/
    openPreEdit(row, code) {
      let Switch = true
      if(code){
        this.current = Object.assign({
          module_code: code
        }, row);
      }else{
        Switch = false
      }
      this.showPreEdit = Switch;
    },
    /* 删除 */
    remove(row) {
      const loading = this.$loading({ lock: true });
      this.$http
        .delete("/Admin/preevaluate/destroy/" + row.id)
        .then((res) => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({ type: "success", message: res.data.msg });
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error("请至少选择一条数据");
        return;
      }
      this.$confirm("确定要删除选中的等级吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({ lock: true });
          this.$http
            .delete(
              "/Admin/preevaluate/destroy/" +
              this.selection.map((d) => d.id).toString()
            )
            .then((res) => {
              loading.close();
              if (res.data.code === 200) {
                this.$message({ type: "success", message: res.data.msg });
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((e) => {
              loading.close();
              this.$message.error(e.message);
            });
        })
        .catch(() => {});
    },
  }
}
</script>

<style scoped>
</style>
